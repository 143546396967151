import React from "react";

export function Logo() {
  return (
    <>
    <div className="logo-background">
      <div className="logo-header">High Stakes</div> 
      <div className="slogan">CRYPTO STAKING GAMES</div>
    </div>
  </>
  );
}
